import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import AnxietyTreatment from '../../components/Specialties/components/AnxietyTreatment';

export default () => (
  <React.Fragment>
    <SEO
      title="Anxiety Treatment Center in Provo, Utah | Mindset Family Therapy"
      description="Most likely you or someone close to you is struggling with anxiety. All of us will or have experienced anxiety at some point in our lives. For some people, it is a lifetime struggle."
      pathname="/specialties/anxiety-treatment"
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498617349/anxietyQuote.jpg"
      article
    />
    <Layout>
      <AnxietyTreatment />
    </Layout>
  </React.Fragment>
);
