const anxietyTreatment = {
  quote1:
    '<p>"Imagine a universe in which your feelings, thoughts, and memories are not your enemy. They\n' +
    'are your history brought into the current context, and your own history is not your&nbsp;enemy."</p><p>STEVEN C. HAYES</p>',
  intro:
    'Anxiety disorders are the most common mental illness in the United States affecting 40 million adults ages' +
    ' 18 and over. People with an anxiety disorder are three to five times more likely to go to the doctor, and six' +
    ' times more likely to be hospitalized for psychiatric disorders than those who do not suffer from anxiety' +
    ' disorders (<a target="_blank" rel="noopener noreferrer"' +
    ' href="https://adaa.org/about-adaa/press-room/facts-statistics">Source:' +
    ' ADAA</a>).<br/><br/>Most likely you or someone close to you is struggling with anxiety. All of us will or have experienced anxiety at some point in our lives. For some people, it is a lifetime struggle. Why is anxiety so pervasive?<br/><br/>The answers to this question will be provided in detail during treatment. However, let’s briefly go over three concepts that individuals experiencing anxiety misunderstand.',
  summary1:
    'When individuals become fused with their mind’s advice to fight their unpleasant feelings and other internal events, they reinforce their brain pathways of anxiety. Though avoidance appears to be a simple and easy solution, it can lead individuals to feeling depressed and hopeless.<br/><br/>The good news is that it doesn’t have to be that way and Mindset Family Therapy has the answers you’ve been looking for in your life.<br/><br/>At Mindset Family Therapy, clinicians will teach you or your loved one how to recognize helpful and unhelpful thoughts. You will learn to look at your internal events such as thoughts, feelings, sensations, and urges with a different mindset. You will learn that the way your mind solves a problem in the outside world does not provide effective solutions in the inside world (thoughts, feelings, sensations, and urges).<br/><br/>You will learn how your mind works and how to respond differently.',
  summary2:
    'As you learn skills during weekly sessions, your therapist will invite you to practice them every day at home, or other settings required for you to learn effectively. As with any type of learning, when you practice consistently every day for long periods of time, your learning will be long lasting. Structural and functional changes will take place in the brain, which will make it possible for you to develop psychological flexibility. It requires patience, work, diligence, and consistency.<br/><br/>We invite you to schedule an appointment and find out what a “doing” and “acting” therapy is like and how you can begin to live the life you desire along with your loved ones and what matters most!',
  treatmentSummary: [
    'Treatment is tailored individually, according to your life style, goals, values, your anxiety, and what you need the most.',

    'We use an integrative model to help our clients develop a different relationship with anxiety.',

    'We implement Cognitive Behavioral Therapy (CBT) as the foundation for our therapeutic work because of its evidence-based practice.',

    'We recognize the amazing plasticity of the brain. Our actions, emotions and thoughts contribute to our brain’s' +
      ' capability to continue to change. When the behavior repeats and the response is consistent, the' +
      ' neuroplasticity will take place, as neurons will wire together reinforcing a particular behavior. Research' +
      ' indicates that the <i>best chance to have a change in our brain is what we do</i>. Thus, Exposure and Response' +
      ' Prevention (ERP) is essential in the treatment of anxiety.',

    'ACT (Acceptance and Commitment Therapy) skills will be taught throughout your treatment. ACT has shown to make a significant difference in treatment when clients begin exposures, making treatment more doable and sustainable.',

    'Mindfulness Training is crucial in learning to observe your internal experiences and notice them for what they are — thoughts, feelings, sensations, and urges.',

    'Art and other evidence based treatment therapeutic skills will also be introduced to assist clients who may feel stuck and unable to implement cognitive skills effectively.',

    'You will recognize your values — what matters most in your life — so that you can return to living a' +
      ' value-focused life instead of spending all your energy, effort, and time with anxiety.',

    'With the guidance of your therapist, you will develop specific and realistic goals related to what matters most so you can live with vitality even when anxiety shows up.',
  ],
  concepts: [
    {
      name: 'Fighting Anxiety',
      definition:
        'When individuals experience anxiety, the fight-or-flight response is set in motion. This is the' +
        ' body’s natural response for survival. When we struggle with anxiety, those feelings are magnified. Our' +
        ' inherent response is to try to get rid of unpleasant feelings and sensations immediately, but it doesn’t work effectively.',
      secondDescription:
        'Experiencing restlessness, fatigue, muscle tension, heart pounding, and shortness of' +
        ' breath, to name a few symptoms of anxiety, is not something we want to encounter on a regular basis. However, when we resist these sensations, our “alarm system” may detect that it’s not doing its job. It sends the message to the other structures in our brain and body to work overtime.',
    },
    {
      name: 'Believing and doing what the mind says',
      definition:
        'Our mind gives us thoughts, ideas and solutions to “help” us stay safe from possible danger. Here' +
        ' is a short silly story to illustrate this point. Once there was a man who lived by the mountains. He had' +
        ' heard there were man-eating bears on those mountains. He didn’t like that idea, and somewhere he read that he could scare bears away by whistling. So he made it a habit to whistle every time he was outside doing chores. Every time his friends came over to visit, they noticed he would always whistle when he was outside of the house. They finally asked him why. He responded, “My whistling keeps man-eating bears away.” His friends answered, “There are not any man-eating bears around.” The man proudly responded, “Yup, it works. My whistling keeps them away.”',
      secondDescription:
        'You may say like this man’s friends said, “This is silly.” However, when individuals' +
        ' experience anxiety, they will do whatever they believe is helpful in order to keep anxiety away, even if to others seems silly or unhelpful.',
    },
    {
      name: 'Avoidance appears to be the best solution',
      definition:
        'When someone struggles with anxiety, their mind provides a myriad of solutions to avoid anything and anyone that could possible trigger their anxiety. Unfortunately, avoidance does not provide the result they wish to have –to get rid of their anxiety, as it keeps reoccurring. They also experience the pain of loneliness and missing out on life because they are not doing what they wish they could do if anxiety was not present.',
    },
  ],
  relatedTopics: [
    {
      title: 'Generalized Anxiety Disorder',
      link: '/specialties/general-anxiety-disorder',
    },
    {
      title: 'Panic',
      link: '/specialties/panic',
    },
    {
      title: 'Social Phobia',
      link: '/specialties/social-phobia',
    },
  ],
};

export default anxietyTreatment;
