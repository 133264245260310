import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import { FaPhone } from 'react-icons/fa';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import BookTeaser from '../../../BookTeaser';
import data from '../../../../data/anxietyTreatment';

import styles from './anxietyTreatment.module.scss';

const phoneIcon = <FaPhone size={22} color="#FFF" />;

const AnxietyTreatment = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498616697/anxiety.jpg';
  const image = 'https://res.cloudinary.com/dewd4pral/image/upload/v1570234744/mtns2.jpg';

  const ActionCard = ({ lastStop }) => {
    return (
      <a href="tel:8014271054">
        <div className={styles.actionCard}>
          <div className={styles.cardIcon}>{phoneIcon}</div>
          {lastStop && (
            <p className={styles.actionLastStop}>
              This is your last stop. You&apos;ve finally found Anxiety treatment providers who
              get&nbsp;you!
            </p>
          )}
          <p className={styles.actionCopy}>Schedule your appointment today!</p>
          <p className={styles.actionPhone}>801-427-1054</p>
        </div>
      </a>
    );
  };

  const relatedTopics = _.map(data.relatedTopics, ({ title, link }, index) => {
    return (
      <li key={index}>
        <Link to={link}>{title}</Link>
      </li>
    );
  });

  return (
    <main>
      <PageHeader
        pageCategory="Specialties"
        pageName="Anxiety Treatment"
        headerImage={background}
      />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Specialized. Experienced. Results.</h2>
        <h2 className={styles.headerSmall}>
          Why choose Mindset Family Therapy for your Anxiety Treatment?
        </h2>

        <ul>
          <li>
            <strong>We specialize in treating anxiety disorders</strong>
            <ul>
              <li className={styles.sub}>We are NOT general practitioners</li>
            </ul>
          </li>

          <li>
            <strong>30+ years of successfully treating anxiety</strong>
            <ul>
              <li className={styles.sub}>We get results!</li>
            </ul>
          </li>

          <li>
            <strong>
              We are regular presenters at the International OCD Foundation conferences
            </strong>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <ul>
          <li>
            <strong>
              We provide anxiety, OCD and OC related disorders consultation and training for other
              psychotherapists
            </strong>
          </li>

          <li>
            <strong>
              We have been trained to treat anxiety, OCD, and OC related disorders by renowned
              experts in the field
            </strong>
          </li>

          <li>
            <strong>We understand your pain based on our own personal experiences</strong>
          </li>

          <li>
            <strong>
              We treat anxiety with evidence-based treatment modalities and stay up to date with the
              latest research and best treatment for anxiety, OCD and OC related disorders. We can
              help you change your relationship with anxiety and start living with vitality and
              meaning!
            </strong>
            <ul>
              <li className={styles.sub}>
                {' '}
                <strong>Cognitive Behavioral Therapy</strong> (CBT) as the foundation for our
                therapeutic work.
              </li>
              <li className={styles.sub}>
                <strong>Exposure and Response Prevention</strong> (ERP). Research indicates that the
                <i> best chance to have a change in our brain is what we do</i>. Thus, ERP is the
                treatment of anxiety.
              </li>
              <li className={styles.sub}>
                <strong>Acceptance and Commitment Therapy</strong> (ACT). ACT is an exposure-based
                model and has ERP built into it. ACT has been shown to be effective in promoting and
                enhancing the success of ERP in individuals struggling with{' '}
                <strong>anxiety, OCD, and OC related disorders</strong>.
              </li>
              <li className={styles.sub}>
                <strong>Mindfulness Training</strong> is crucial in learning to observe your
                internal experiences and notice them for what they are — thoughts, feelings,
                sensations, and urges.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard lastStop />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>What to Expect in Treatment</h2>
        <ul>
          <li>
            Treatment is tailored individually, according to your lifestyle, goals, values, your
            anxiety, and what you need the most.
          </li>
          <li>
            Learn psychological flexibility skills to disrupt your <strong>anxiety cycle</strong>.
            <ul>
              <li className={styles.sub}>
                Become more <strong>open</strong> to painful internal experiences. Learn skills to
                get unstuck from them and allow them to come and go without getting caught in the
                anxiety trap.
              </li>
              <li className={styles.sub}>
                Enhance your <strong>awareness</strong> so you can connect with the here and now and
                recognize that you are aware of being aware without having to become absorbed in
                avoidant behaviors to get rid of internal events (e.g., thoughts and feelings).
              </li>
              <li className={styles.sub}>
                Become <strong>actively engaged</strong> in what is worth (values) your focus, time,
                and energy and choose to do what matters most in your life.
              </li>
            </ul>
          </li>
        </ul>

        <p className={styles.copy}>
          <br />
          With the guidance of your therapist, you will develop specific and realistic goals related
          to what matters most so you can live with vitality even when anxiety shows up..
        </p>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <p style={{ textAlign: 'center' }} className={styles.copy}>
          <strong>
            You can learn to be actively engaged in what matters most each and every day!
          </strong>
          <br />
          <br />
          <i>Need extra help with anxiety? This book might enhance your treatment.</i>
        </p>
      </div>

      <div>
        <BookTeaser bookName="Let Go of Anxiety" />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>Additional Information</h2>
        <p className={styles.copy}>
          Anxiety disorders are the most common mental illness in the United States affecting 40
          million adults ages 18 and over. People with an anxiety disorder are three to five times
          more likely to go to the doctor, and six times more likely to be hospitalized for
          psychiatric disorders than those who do not suffer from anxiety disorders (Source:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://adaa.org/about-adaa/press-room/facts-statistics"
          >
            ADAA
          </a>
          ).
          <br />
          <br />
          Most likely you or someone close to you is struggling with anxiety. All of us will or have
          experienced anxiety at some point in our lives. For some people, it is a lifetime struggle
        </p>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>Why is anxiety so pervasive?</h2>
        <p className={styles.copy}>
          The answers to this question will be provided in detail during treatment. However, let’s
          briefly go over three concepts that individuals experiencing anxiety most commonly
          misunderstand.
          <br />
          <br />
          <strong>Fighting Anxiety</strong>. When individuals experience anxiety, the
          fight-or-flight response is set in motion. This is the body’s natural response for
          survival. When we struggle with anxiety, those feelings are magnified. Our inherent
          response is to try to get rid of unpleasant feelings and sensations immediately, but it
          doesn’t work effectively.
          <br />
          <br />
          Experiencing restlessness, fatigue, muscle tension, heart pounding, and shortness of
          breath, to name a few symptoms of anxiety, is not something we want to encounter on a
          regular basis. However, when we resist these sensations, our “alarm system” may detect
          that it’s not doing its job. It sends the message to the other structures in our brain and
          body to work overtime.
          <br />
          <br />
          <strong>Believing and doing what the mind says</strong>. Our mind provides us with
          thoughts, ideas and solutions to “help” us stay safe from possible danger. Here is a short
          silly story to illustrate this point. Once there was a man who lived by the mountains. He
          had heard there were man-eating bears on those mountains. He didn’t like that idea, and
          somewhere he read that he could scare bears away by whistling. So he made it a habit to
          whistle every time he was outside doing chores. Every time his friends came over to visit,
          they noticed he would always whistle when he was outside of the house. They finally asked
          him why he did that. He responded, “My whistling keeps man-eating bears away.” His friends
          answered, “There are not any man-eating bears around.” The man proudly responded, “Yup, it
          works. My whistling keeps them away.”
          <br />
          <br />
          You may say like this man’s friends said, “This is silly.” However, when individuals
          experience anxiety, they will do whatever they believe is helpful in order to keep anxiety
          away, even if to others seems silly or unhelpful.
          <br />
          <br />
          <strong>Avoidance appears to be the best solution</strong>. When someone struggles with
          anxiety, their mind provides a myriad of solutions to avoid anything and anyone that could
          possible trigger their anxiety. Unfortunately, avoidance does not provide the result they
          wish to have –to get rid of their anxiety, as it keeps reoccurring. They also experience
          the pain of loneliness and missing out on life because they are not doing what they wish
          they could do if anxiety was not present.
          <br />
          <br />
          When individuals become fused with their mind’s advice to fight their unpleasant feelings
          and other internal events, they reinforce their brain pathways of anxiety. Though
          avoidance appears to be a simple and easy solution, it can lead individuals to feeling
          depressed and hopeless.
        </p>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <p className={styles.copy}>
          <strong>
            The good news is that it doesn’t have to be that way and Mindset Family Therapy has the
            answers you’ve been looking for in your life!
          </strong>
          <br />
          <br />
          At Mindset Family Therapy, clinicians will teach you or your loved one how to recognize
          helpful and unhelpful thoughts. You will learn to look at your internal events such as
          thoughts, feelings, sensations, and urges with a different mindset. You will learn that
          the way your mind solves a problem in the outside world does not provide effective
          solutions in the inside world (thoughts, feelings, sensations, and urges).
          <br />
          <br />
          <strong>You will learn how your mind works and how to respond differently!</strong>
        </p>
      </div>

      <QuoteImage image={image} maxWidth={560} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Related Topics</h2>
        <ul>{relatedTopics}</ul>
      </div>
    </main>
  );
};

export default AnxietyTreatment;
